<template>
  <div class="container">
    <div class="row">
      <slot name="header-logo">
        <a href="https://loolootech.com" class="col-12 logo-section my-5">
          <div class="logo">
            <img src="@/assets/htr-images/logo.png" />
          </div>
          <div class="demo-icon">
            <img src="@/assets/htr-images/demo_icon.png" />
          </div>
        </a>
      </slot>
      <slot name="header-content"></slot>
      <div class="col-12 col-md-6 p-3" v-if="!hasInputContentCenterSlot">
        <slot name="input-content"></slot>
      </div>
      <div class="col-12 col-md-6 p-3 align-self-center" v-if="hasInputContentCenterSlot">
        <slot name="input-content-center"></slot>
      </div>
      <div class="col-12 col-md-6 p-3">
        <slot name="output-content"></slot>
      </div>
      <slot name="footer-content"></slot>
      <div class="col-12 col-md-6 p-3">
        <div class="footer-section">
          <div class="contact">Contact Us</div>
          <hr class="line" />
          <div class="contact-description">
            For more information on how we can help your business, contact us at
          </div>
          <div class="email-contact">
            <a href="mailto:contact@loolootech.com" class="text-reset text-decoration-none">
              <img
                class="me-3"
                src="@/assets/htr-images/@_icon.png"
              />contact@loolootech.com
            </a>
          </div>
          <div class="tel-contact">
            <a href="tel:+66902180226" class="text-reset text-decoration-none">
              <img
                class="me-3"
                src="@/assets/htr-images/tel_icon.png"
              />+669-0218-0226
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 p-3">
        <div class="footer-section" id="right">
          <div class="contact">DEEP TECHNOLOGY</div>
          <div class="contact">TAILORED TO YOU</div>
          <div>
            <a href="https://facebook.com/loolootech">
              <img
                class="ms-3 mt-3"
                src="@/assets/htr-images/facebook.png"
              />
            </a>
            <a href="https://instagram.com/loolootech">
              <img
                class="ms-3 mt-3"
                src="@/assets/htr-images/ig.png"
              />
            </a>
            <a href="https://youtube.com/channel/UCpbmGxbsqf5IOSZ0IRw_b4Q">
              <img
                class="ms-3 mt-3"
                src="@/assets/htr-images/youtube.png"
              />
            </a>
            <a href="https://m.me/loolootech">
              <img
                class="ms-3 mt-3"
                src="@/assets/htr-images/messeger.png"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 my-4 d-flex justify-content-center coypright">© {{ new Date().getFullYear() }}&nbsp;<a href="https://loolootech.com" class="text-decoration-none">Looloo Technology.</a>&nbsp;All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTemplate',
  computed: {
    hasInputContentCenterSlot() {
      return !!this.$slots["input-content-center"];
    },
  },
}
</script>

<style>
body {
  background-image: url('~@/assets/htr-images/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-padding {
  padding: 29px 180px;
}

.logo-section {
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
}

.logo {
  margin-right: 15px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #ffffff;
}

.description {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0168em;
  color: #ffffff;
}

.footer-section {
  margin-top: 109px;
  color: #ffffff;
}

.contact {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
}

hr.line {
  width: 83px !important;
  height: 5px !important;
  background-color: #ffffff;
  opacity: 1;
  margin: 17px 0 12px 0;
}

.contact-description {
  width: 335px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0168em;
  margin-bottom: 18px;
}

.email-contact {
  display: flex;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0168em;
  align-items: flex-end;
  margin-bottom: 11px;
}

.tel-contact {
  display: flex;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.0168em;
  align-content: center;
  align-items: center;
}

.footer-section#right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#right .contact {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
}

.coypright{
  color: #ffffff;
}

.menu-tab-group {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
}

.buscard,
.carbook,
.invoice,
.loc {
  width: 250px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.active-tab {
  border-bottom: 8px solid #f8c784;
  color: #f8c784;
}
</style>
