<template>
  <button type="button" class="btn btn-primary border-0 submit-button px-4">
    <span class="d-flex justify-content-center align-items-center">
      <span>{{ text }}</span>
      <span v-if="text">&nbsp;</span>
      <span class="material-icons-outlined align-self-center" v-if="icon">
        {{ icon }}
      </span>
    </span>
    
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    icon: String,
    text: String
  }
}
</script>

<style scoped>
  .submit-button::v-deep{
    height:70px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    border-radius: 20px;
  }
</style>